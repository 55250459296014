import * as React from "react"
import Layout from "@lekoarts/gatsby-theme-minimal-blog/src/components/layout"
import { useForm } from "react-hook-form"
import { useState } from 'react';

export default function App() {
    const {
      setError,
      register,
      handleSubmit,
      formState: { errors },
    } = useForm()
    const [message, setMessage] = useState('');
    const onSubmit = data => {
      console.log(JSON.stringify(data))
      fetch(`https://grurlyi.tienvv.com/api/v1`, {
        method: `POST`,
        body: JSON.stringify(data),
        headers: {
          "content-type": `application/json`,
        },
      })
        .then(res => res.json())
        .then(body => {
            console.log(`response from API:`, body)
            if (body.error) {
                setError('server', {
                type: "server",
                message: body.error,
                });
            }
            if (body.short){
                setMessage(body.short)
            }
        })
    }
  
    console.log({ errors })
  
    return (
        <Layout>
            <div className="flex flex-col ...">
                <div style={{ textAlign: "center" }}>
                    <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">gRUrlYI Jangu Bang for your URL</h1>
                    <p className="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-600">Like the famous Wukong's Ruyi Jangu Bang, this can help you shorten your url</p>
                </div>
                <div>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        // style={{ display: `block`, width: 400 }}
                        className="max-w-sm mx-auto"
                    >
                        <div className="mb-5">
                            <label htmlFor="Original URL" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Original URL</label>
                            <input 
                                type="text" 
                                id="original-url" 
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                                placeholder="www.yourlenthyurl.com"
                                {...register("url", { required: true, 
                                    pattern: {
                                    //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    value: /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i,
                                    message: "Invalid URL"
                                    }
                                })}
                            />
                            {errors.url && <p style={{ color: `#FF0000` }}>{errors.url.message}</p>}
                        </div>
                        <div className="mb-5">
                            <label htmlFor="Custom URL" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Custom URL</label>
                            <input 
                                type="text" 
                                id="custom-short-url" 
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" 
                                {...register("short", { required: false, maxLength: 256 })}
                            />
                        </div>
                        <div className="mb-5">
                            <label htmlFor="Expiry" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Expiry</label>
                            <input 
                                type="number" 
                                id="expiry" 
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" 
                                placeholder="1-720"
                                {...register("expiry", { required: false, valueAsNumber: true, 
                                    max: {
                                        value: 720,
                                        message: 'Maximum 720 hours'
                                    },
                                    min: {
                                        value: 1,
                                        message: 'Minimum 1 hour'
                                    }
                                })}
                            />
                            {errors.expiry && <p style={{ color: `#FF0000` }}>{errors.expiry.message}</p>}
                        </div>
                        <div className="mb-5">
                            <input type="submit" value="gRUrlYI" className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium block w-full rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"/>
                        </div>
                    </form>
                </div>
                <div style={{ textAlign: "center" }}>
                    {errors.server && <p style={{ color: `#FF0000` }}>{errors.server.message}</p>}
                    {message && <p  className="mb-4 text-2xl font-extrabold text-gray-900 dark:text-white md:text-3xl lg:text-4xl"><span class="text-transparent bg-clip-text bg-gradient-to-r to-zinc-900 from-gray-400">{message}</span> </p>}
                </div>
            </div>
            
        </Layout>
    )
  }
